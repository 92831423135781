import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Zvláštnosti ostrovní bioty" />
    <h1>Zvláštnosti ostrovní bioty</h1>

<p>Zvl&aacute;&scaron;tnosti ostrovn&iacute; bioty jsou zaj&iacute;mav&yacute;m podt&eacute;matem <Link to="/biogeografie/ostrovni/">ostrovn&iacute; biogeografie</Link>, kter&aacute; norm&aacute;lně ře&scaron;&iacute; zejm&eacute;na m&iacute;ry počtu druhů a jejich obměnu v průběhu času. Zde se ře&scaron;&iacute; samotn&eacute; vlastnosti ostrovn&iacute; bioty, kter&aacute; je specifick&aacute;, s rozmanitou morfologi&iacute; a zvl&aacute;&scaron;tn&iacute;mi adaptacemi.</p>
<hr />
<h2>Vysok&yacute; endemismus</h2>
<p>Vy&scaron;&scaron;&iacute; m&iacute;ra&nbsp;<Link to="/biogeografie/areografie/typy-rozsireni/#endemity">endemismu</Link> než v pevninsk&yacute;ch společenstvech je pro ostrovn&iacute; biotu př&iacute;značn&aacute;. Endemismus vzrůst&aacute; s izolovanost&iacute; a velikost&iacute; ostrova. Izolace způsobuje přeru&scaron;en&iacute; toku genů a <Link to="/biogeografie/evolucni/speciace/">alopatrickou speciaci</Link>, ta je n&aacute;sledov&aacute;na zejm&eacute;na v př&iacute;padě velk&yacute;ch ostrovů adaptivn&iacute; radiac&iacute;. Na velk&yacute;ch ostrovech je nav&iacute;c niž&scaron;&iacute; m&iacute;ra extinkce druhů.</p>
<p>Počet endemick&yacute;ch rostlin značně souvis&iacute; se změnou rozlohy ostrova od posledn&iacute;ho glaci&aacute;ln&iacute;ho maxima (zkratka pro maximum LGM), ztr&aacute;ta rozlohy ostrova ovlivňuje negativněji endemity než běžn&eacute; druhy.</p>
<hr />
<h2>Struktura ostrovn&iacute;ch společenstev</h2>
<p>Struktura ostrovn&iacute;ch společenstev je ovlivněna předev&scaron;&iacute;m t&iacute;m, že ostrovy jsou osidlov&aacute;ny prim&aacute;rně druhy s dobrou schopnost&iacute; <Link to="/biogeografie/disperze/">disperse</Link> a dobrou životaschopnost&iacute; v taměj&scaron;&iacute;ch podm&iacute;nk&aacute;ch.</p>
<p>Zat&iacute;mco &scaron;patn&iacute; migranti osidluj&iacute; jen nejbliž&scaron;&iacute; ostrovy, ti nejlep&scaron;&iacute; b&yacute;vaj&iacute; př&iacute;tomn&iacute; na v&scaron;ech. Společenstva vzd&aacute;len&yacute;ch ostrovů jsou tak tvořeny v&yacute;běrem druhů s nejlep&scaron;&iacute;mi migračn&iacute;mi schopnostmi, to způsobuje tzv. hn&iacute;zdovitost (nestedness) ostrovn&iacute;ch společenstev.</p>
<p>Hn&iacute;zdovitost je pojem pro vnořenost syst&eacute;mu, tedy fakt, že druhy společenstva vzd&aacute;leněj&scaron;&iacute;ho od pevniny jsou podmnožinou toho bliž&scaron;&iacute;ho pevnině. Ta v&scaron;ak může b&yacute;t způsobena i selektivn&iacute;m vym&iacute;r&aacute;n&iacute;m druhů. Schopnost migrace tak nemus&iacute; b&yacute;t jedinou či prim&aacute;rn&iacute; př&iacute;činou.</p>
<hr />
<h2>Typy adaptac&iacute;</h2>
<p>Mezi typick&eacute; adaptace ostrovn&iacute; bioty patř&iacute;:</p>
<ul>
<li><strong>sn&iacute;žen&iacute; dispersn&iacute;ch schopnost&iacute;</strong></li>
<li><strong>nel&eacute;tavost pt&aacute;ků</strong></li>
<li><strong>dřevnatěn&iacute; a gigantismus rostlin</strong></li>
<li><strong>gigantismus a nanismus živočichů</strong> na z&aacute;kladě <strong>ostrovn&iacute;ho pravidla</strong></li>
</ul>
<h3>Sn&iacute;žen&iacute; dispersn&iacute;ch schopnost&iacute;</h3>
<p>Ačkoliv jsou ostrovy typicky os&iacute;dleny taxony s vynikaj&iacute;c&iacute; schopnost&iacute; <Link to="/biogeografie/disperze/">disperze</Link>, mnoho ostrovn&iacute;ch forem, druhů m&aacute; jen minim&aacute;ln&iacute; schopnosti disperze. To je pravděpodobně důsledek adaptace na ostrovn&iacute; podm&iacute;nky.</p>
<p>Př&iacute;kladem jsou druhy netop&yacute;rů na Nov&eacute;m Z&eacute;landu (<em>Mystacinidae</em>), kteř&iacute; se plaz&iacute; po zemi a hrabou nory pomoc&iacute; specializovan&yacute;ch &scaron;pič&aacute;ků. Schopnost letu v&scaron;ak je&scaron;tě &uacute;plně neztratily.</p>
<h3>Nel&eacute;tavost u pt&aacute;ků</h3>
<p>Nel&eacute;tavost u pt&aacute;ků se vyvinula mnohokr&aacute;t a nez&aacute;visle na sobě. Např&iacute;klad na Nov&eacute;m Z&eacute;landu je 25 až 35&nbsp;% v&scaron;ech suchozemsk&yacute;ch a sladkovodn&iacute;ch pt&aacute;ků nel&eacute;tav&yacute;ch.</p>
<p>Nelet&aacute;vost se pravděpodobně vyvinula vlivem absenc&iacute; pred&aacute;torů a omezen&yacute;ch zdrojů. Často je spojov&aacute;na se zv&yacute;&scaron;en&iacute;m velikosti druhu, což může b&yacute;t prim&aacute;rn&iacute;m vlivem na ztr&aacute;tu schopnosti letu.</p>
<h3>Dřevnatěn&iacute; a gigantismus rostlin</h3>
<p>Dřevnatěn&iacute; a gigantismus rostlin na ostrovech je vět&scaron;inou spojeno s ekologick&yacute;m posunem niky rostlinn&yacute;ch druhů, jejichž předkov&eacute; byli dř&iacute;ve bylinn&eacute;ho vzrůstu a rostli na otevřen&yacute;ch stanovi&scaron;t&iacute;ch rann&yacute;ch sukcesn&iacute;ch st&aacute;di&iacute;. Vlivem sukcese pak tyto druhy začaly obsazovat voln&eacute; ostrovn&iacute; niky lesn&iacute;ch druhů.</p>
<p>Původně byliny tak na ostrovech často vytv&aacute;ř&iacute; keřovit&eacute;, dřevnat&eacute; formy vzrůstu. Na Kan&aacute;rsk&yacute;ch ostrovech lze např&iacute;klad spatřit keřovit&eacute; kopřivy či kopretinovce.</p>
<h3>Gigantismus a nanismus živočichů</h3>
<p>Mnoho ostrovn&iacute;ch druhů se v&yacute;znamně li&scaron;&iacute; ve velikosti těla oproti sv&yacute;m př&iacute;buzn&yacute;m na pevnině. Druhy jsou pak buď vět&scaron;&iacute; (gigantismus), nebo naopak men&scaron;&iacute; (nanismus).</p>
<p><strong>Podle ostrovn&iacute;ho pravidla</strong> (Van Valen, 1973) doch&aacute;z&iacute; u velk&yacute;ch savců ke zmen&scaron;ov&aacute;n&iacute; těla a u mal&yacute;ch savců ke zvět&scaron;ov&aacute;n&iacute;. Na ostrovech totiž panuje jak&aacute;si tendence k m&iacute;stně optim&aacute;ln&iacute; velikosti těla, kter&aacute; je podm&iacute;něna:</p>
<ul>
<li>taxonomickou př&iacute;slu&scaron;nost&iacute;</li>
<li>klimatem</li>
<li>zeměpisnou &scaron;&iacute;řkou</li>
<li>plochou a izolac&iacute; ostrova</li>
<li>nosnou kapacitou prostřed&iacute; (př&iacute;tomnost/Linkbsence kompetitorů)</li>
</ul>
<p>Mezi <strong>selekčn&iacute; faktory způsobuj&iacute;c&iacute; zvět&scaron;en&iacute;</strong> těla patř&iacute;:</p>
<ul>
<li>možnost využit&iacute; &scaron;ir&scaron;&iacute;ch zdrojů (mal&aacute; i velk&aacute; kořist/semena)</li>
<li>možnost vět&scaron;&iacute; investice do reprodukce (vět&scaron;&iacute; snů&scaron;ka a potomstvo)</li>
<li>vět&scaron;&iacute; &scaron;ance vyhr&aacute;t souboje o teritoria</li>
<li>vět&scaron;&iacute; schopnost překonat kr&aacute;tkodob&yacute; nedostatek energie</li>
</ul>
<p>Naopak mezi <strong>selekčn&iacute; faktory vedouc&iacute; ke zmen&scaron;en&iacute;</strong> těla patř&iacute;:</p>
<ul>
<li>m&eacute;ně zdrojů pro přežit&iacute; a reprodukci (důležit&eacute; zejm&eacute;na na mal&yacute;ch ostrovech s omezen&yacute;mi zdroji)</li>
<li>snaz&scaron;&iacute; specializace a lep&scaron;&iacute; využ&iacute;v&aacute;n&iacute; dostupn&yacute;ch zdrojů</li>
<li>možnost využ&iacute;t men&scaron;&iacute; &uacute;kryty před pred&aacute;tory a nepř&iacute;zn&iacute; klimatu</li>
</ul>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Zvláštnosti ostrovní bioty</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/ostrovni/"><button className="inv">&larr; Ostrovní biogeografie</button></Link>
    <Link to="/biogeografie/invazni-ekologie/"><button className="inv">Invazní ekologie &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
